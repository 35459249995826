import './App.css';
import { Map } from './Map.jsx';
import 'semantic-ui-css/semantic.min.css'
import { useState, useEffect } from 'react';
import {
    Segment,
    Dropdown,
    Grid,
    Card,
} from 'semantic-ui-react'



function App() {


    const [ wormholes, setWormholes ] = useState([]);
    const [ wormholeOptions, setWormholeOptions ] = useState([]);
    const [ selectedWormhole, setSelectedWormhole ] = useState(null);


    useEffect(() => {
        fetch('/wormhole.json').then(e => e.json()).then(e => {
            setWormholes(e)
            setWormholeOptions(Object.keys(e).map(e => ({key: e, value: e, text: e})))
        });
    }, []);


    return (
        <div>
            <Segment
                   inverted
                   textAlign='center'
                   style={{ padding: '1em 0em' }}
                   vertical
               >
                   Albion Roads
               </Segment>
            <Segment vertical>
                <Grid container 
                    stackable verticalAlign='top'>
                    <Grid.Row>
                        <Dropdown
                            placeholder='Select Location'
                            fluid
                            search
                            selection
                            onChange={(e, {value}) => setSelectedWormhole(value)}
                            options={wormholeOptions}
                        />
                    </Grid.Row>
                </Grid>
            </Segment>
            { selectedWormhole && wormholes[selectedWormhole] && (
            <Segment vertical>
                <Grid container 
                    stackable verticalAlign='top'>
                    <Grid.Row centered>
                        <Grid.Column mobile={16} computer={12}
                            style={{ maxWidth: 900}}
                        >
                        <Card fluid> 
                            <Card.Content textAlign="center">
                                <Card.Header>{selectedWormhole}</Card.Header>
                                <Card.Meta>{wormholes[selectedWormhole].tier} - {wormholes[selectedWormhole].type}</Card.Meta>
                            </Card.Content>
                            <Card.Content extra>
                                <Map system={wormholes[selectedWormhole]} name={selectedWormhole} />
                            </Card.Content>
                        </Card>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Segment>
            )}
        </div>
    );
}

export default App;
