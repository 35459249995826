
export function Map(props) {
    const { system } = props;
    if (!system || !system.offset || !system.origin )
        return (<div />)

    const image = `/img/${props.system?.img}.webp`
    const offset = props.system.offset.split(" ").map(e => (parseInt(e)));
    const size = props.system.size || 1200;

    return (
            <svg
                viewBox={`${offset[0]} ${offset[1]} ${size} ${size}`}
                transform={`scale(0.7 -0.7) rotate(-45)`}

            >
        <g
        >
                <image 
                    height={size}
                    width={size}
                    x={offset[0]} y={offset[1]}
                    href={image} />
                {props.system.content.map((instance, idx) => {
                    let [x, y] = instance.pos
                    let tier = false;
                    if (instance.tier) 
                        tier = instance.tier.match(/[0-9]/)[0] 

                    return (
                        <g key={idx}>
                            <image x={x} y={y}  
                                transform={`translate(-24 -24) rotate(-135) scale(-2 2)`}
                                transform-origin={`${x+24} ${y+24}`}
                                href={`./icons/${instance.type.toLowerCase()}.png`} />
                            { tier && (
                                <g>
                                    <circle cx={x-20} cy={y+20} stroke="black" fill="white" r={16} />
                                    <text x={x-20} y={y+20}
                                        transform={`scale(-1 1) rotate(135) translate(-6 8)`}
                                        transform-origin={`${x-20} ${y+20}`}
                                        fontSize="22"
                                        stroke="black">{ tier }</text>
                                </g>
                            ) }
                        </g>)

                })}
            </g>
            </svg>
    )
}
